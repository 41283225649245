.Example {

  .Example__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 10px;
  }

  .Example__container__load {
    margin-top: 1em;
    color: white;
  }

  .Example__container__document {

    .react-pdf__Document {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .react-pdf__Page {
      max-width: calc(100% - 1em);
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      margin: 1em 0.5em 1em 0.5em;

      canvas {
        max-width: 100%;
        height: auto !important;
      }
    }

    .react-pdf__message {
      padding: 20px;
      color: white;
    }
  }

}