body {
  font-family: 'Tiro Devanagari Marathi', serif;
}

.logo {
  max-height: 75px;
}

.logo-sm {
  max-height: 45px;
}

.logo-xs {
  max-height: 30px;
}

.pdf-container .react-pdf__Page {
  border: 2px solid #FF0003;
}

.vh-90 {
  height: 90vh !important;
}

.navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E") !important;
}

.navbar-toggler {
  border-color: rgb(255, 255, 255);
}

.navbar-toggler:focus {
  box-shadow: 255 255 255 var(--bs-navbar-toggler-focus-width);
}